import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthResults, CurrentState, GthAuthService, GthLoadingService, LoginError } from '@gth-legacy';
import { LoginType } from '@index/enums';
import { GthUserModel } from '@sentinels/models';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import { APP_ROUTES } from '@shared/helpers';
// import { PwaNotificationsService } from '@shared/services/pwa-notifications.service';
import { UnsafeBrowserService } from '@shared/services/unsafe-browser.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharedAuthService {
  private auth = inject(GthAuthService);
  private spinner = inject(GthLoadingService);
  private router = inject(Router);
  private snackBar = inject(MatSnackBar);
  private unsafeService = inject(UnsafeBrowserService);
  private safeWindow = inject(SrvSafeWindowService);
  private destroy = inject(DestroyRef);

  private unsafeBrowser = toSignal(this.unsafeService.unsafe$);

  loading = signal(false);
  private routes = APP_ROUTES;

  constructor() {
    this.initService();
  }

  public callInitService() { // To make testing easier
    this.initService();
  }

  /**
   * Called immediately when service is instantiated and sets up listener for auth state.
   */
  private initService(): void {
    this.auth.storeListenAuthState().pipe(
      takeUntilDestroyed(this.destroy),
      tap((authState) => {
        this.spinner.show = false;
        this.loading.set(false);
        switch (authState.state) {
          case CurrentState.Loading:
            this.loading.set(true);
            break;
          case CurrentState.Success:
            if (authState?.login?.success) {
              /** Handle successful login logic */
              this.onSocialResponse(authState.login);
            } else if (authState?.login?.errorType) {
              this.onLoginError(authState.login.errorType);
            }
            break;
          case CurrentState.Pending:
            break;
          case CurrentState.Error:
            /** Handle error login logic */
            this.onLoginError(authState.login.errorType);
            break;
          default: break;
        }
      }),
    ).subscribe();
  }

  private async onSocialResponse(results: AuthResults) {
    // await this.requestNotificationPermission(results.user);
    if (results.newUser) {
      const data = {
        results,
      };
      this.router.navigate([this.routes.MoreInfo], { state: { data } });
      return;
    }
    this.router.navigate([this.routes.Home]);
  }

  // private async requestNotificationPermission(user: GthUserModel) {
  //   this.notifications.request(user);
  // }

  private onLoginError(errorType: LoginError) {
    let errorMessage = '';
    switch (errorType) {
      case LoginError.NetworkError:
        errorMessage = 'Error reaching Gametime Hero. Please try again.';
        break;
      case LoginError.InvalidUser:
        errorMessage = 'Username or password is not valid.  Please try again.';
        break;
      case LoginError.NoPasswordSet:
        errorMessage =
          'Please check your email to set a password for this account.';
        break;
      case LoginError.CancelledByUser:
        // The user didn't authenticate, so do NOT display an error
        break;
      case LoginError.Unknown:
      default:
        errorMessage = 'Something went wrong. Please try again.';
        break;
    }

    if (errorMessage) {
      this.snackBar.open(errorMessage);
    }
  }

  public isBrowserValid(loginType: LoginType): boolean {
    if (this.unsafeBrowser() && loginType !== LoginType.Email) {
      // eslint-disable-next-line max-len
      const snack = this.snackBar.open('You may not use Google auth through Messenger; kindly use a web browser.', 'Copy URL');
      snack.onAction().subscribe(() => {
        this.unsafeService.copyUrl(this.safeWindow.location.href);
      });
      return false;
    }
    return true;
  }
}
